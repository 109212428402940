import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic01.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic02.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic03.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic04.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic05.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic06.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic07.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic08.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic09.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic10.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic11.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic12.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic13.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic14.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic15.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic16.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic17.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic18.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic19.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic20.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic21.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic22.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic23.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic24.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic25.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic26.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic27.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic28.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic29.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/7-june-1/pic30.JPG",
];

export default function kartavyapath() {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">CLASS XII PRESENTATION: KARTAVYA PATH</h1>

        <p>
          To encourage the students to proactively explore their the 'Path of
          Duty' by engaging themselves in constructive activities with focus on
          ethical values and principles, the Class XII of Chinmaya Vidyalaya,New
          Delhi, showcased a Class Presentation on the topic – ‘Kartavya Path –
          Path of Duty’. The presentation commenced with a dance on Saraswati
          Vandana. A skit portraying Modern Krishna guiding teenager Arjuna
          about the ways to deal with the issues caused by peer pressure, social
          media influence and drug abuse enriched everyone with the principles
          that resonate with our inner calling – our Dharma. A rap performance
          elucidating the distractions as a barrier faced by teenagers while
          staying on the right path left was greatly appreciated by the
          audience. The parents were able to appreciate the fact that teenagers
          are faced with a variety of social, physical and psychological
          problems in life and it's their duty to support their child during
          hardships .
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
